<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>产品管理</a-breadcrumb-item>
        <a-breadcrumb-item>配件产品列表</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search
        :fields="fields"
        @search="onSearch"
        @reset="onReset"
        ref="search"
      >
        <template>
          <a-form-item label="产品部位" class="common-search_form-item">
            <a-select
              v-model="form.position"
              :options="positionList"
              allow-clear
              @change="positionOnChange"
            />
          </a-form-item>
        </template>
        <template>
          <a-form-item label="产品功用" class="common-search_form-item">
            <a-select
              v-model="form.functionality"
              :options="funcList"
              allow-clear
            />
          </a-form-item>
        </template>
      </common-search>
      <a-row class="button-container-second" v-if="isOperationHeadquarters">
        <a-space>
          <a-button type="primary" @click="onNew">新增</a-button>
          <a-button @click="onBatchChange(true)">批量上架</a-button>
          <a-button @click="onBatchChange(false)">批量下架</a-button>
          <import-product :factory-list="factoryList" @reset="onRefresh" />
          <a-button type="link" icon="download" :href="downloadTemplate">
            下载导入模板
          </a-button>
          <a-popconfirm
            title="是否确认生成实时价格?"
            ok-text="确认"
            cancel-text="取消"
            @confirm="initPolicy"
          >
            <a-button :loading="initPolicyLoading">
              生成实时价格
            </a-button>
          </a-popconfirm>
        </a-space>
      </a-row>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          row-key="productId"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          }"
          @change="onPage"
        >
          <div
            slot="productImg"
            v-viewer
            slot-scope="text, record"
            class="product-img-container"
          >
            <div
              v-if="record.productImages && record.productImages.length !== 0"
              class="product-img-container"
            >
              <img
                v-if="!record.productImages[0].thumbUrl"
                :src="imageBaseUrl + record.productImages[0].fileUrl"
                :data-source="imageBaseUrl + record.productImages[0].fileUrl"
                alt="产品图片"
              />
              <img
                v-else
                :src="imageBaseUrl + record.productImages[0].thumbUrl"
                :data-source="imageBaseUrl + record.productImages[0].fileUrl"
                alt="产品图片"
              />
            </div>
            <div v-else></div>
          </div>
          <a-space slot="action" slot-scope="text, record">
            <a class="btn-operation" @click="onDetail(record)">详情</a>
            <template v-if="isOperationHeadquarters">
              <a
                v-if="record.productStatus === '上架'"
                class="btn-operation"
                @click="onEnableFlagUpdate(record)"
              >
                下架
              </a>
              <a
                v-else
                class="btn-operation"
                @click="onEnableFlagUpdate(record)"
              >
                上架
              </a>
              <a-popconfirm
                title="确认是否删除?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="onDelete(record)"
              >
                <a v-if="!record.enableFlag" class="btn-operation">删除</a>
              </a-popconfirm>
            </template>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import {
  deleteProduct,
  fetchAllFactoriesList,
  fetchProductList,
  modifyProductStatus
} from "@/services/ProductService";
import { imageBaseUrl } from "@/services/HttpService";
import { fetchDict } from "@/services/DictService";
import { tsFormat } from "@/components/DateUtils";
import ImportProduct from "./ImportProduct.vue";
import { strategyInitPolicy } from "@/services/ValueBag";

const tblColumns = [
  {
    title: "产品图",
    key: "productImg",
    scopedSlots: { customRender: "productImg" },
    width: "10%"
  },
  {
    title: "产品名称",
    dataIndex: "productName",
    width: "18%"
  },
  {
    title: "产品编码",
    dataIndex: "productCode",
    width: "15%"
  },
  {
    title: "产品状态",
    dataIndex: "productStatus",
    width: "15%"
  },
  {
    title: "发布时间",
    dataIndex: "createTime",
    width: "20%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  components: {
    ImportProduct
  },
  data() {
    return {
      initPolicyLoading: false,
      fields: [
        {
          label: "产品名称",
          key: "productName",
          component: "j-input"
        },
        {
          label: "产品编码",
          key: "productCode",
          component: "j-input"
        },
        {
          label: "产品状态",
          key: "productStatus",
          component: "j-select",
          defaultValue: "-1",
          options: [
            { value: "-1", label: "全部" },
            { value: "1", label: "上架" },
            { value: "0", label: "下架" }
          ]
        }
      ],
      downloadTemplate: `${process.env.BASE_URL}产品导入模板.xlsx`,
      form: {
        functionality: "",
        position: ""
      },
      funcList: [],
      tblData: [],
      factoryList: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      selectedRowKeys: [],
      setStandardVisible: false,
      positionList: [],
      imageBaseUrl,
      isOperationHeadquarters: true,
      info: {}
    };
  },
  created() {
    this.isOperationHeadquarters = (
      localStorage.getItem("roles") || []
    ).includes("OperationHeadquartersAdmin");
    if (this.isOperationHeadquarters) {
      this.fields.push({
        label: "制造原厂",
        key: "factoryId",
        component: "j-select-search",
        options: [],
        getOptions: () => {
          return this.onLoadFactory();
        }
      });
    }
  },
  mounted() {
    this.onLoadList();
    this.onLoadDict();
  },
  methods: {
    //更新价格
    initPolicy() {
      this.initPolicyLoading = true;
      strategyInitPolicy().then(resp => {
        this.initPolicyLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("操作成功");
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 获取全部制造原厂
    async onLoadFactory() {
      const resp = await fetchAllFactoriesList();
      if (resp.data.code === "SUCCESS") {
        const options = [];
        this.factoryList = [];
        resp.data.data.records.forEach(item => {
          options.push({
            label: item.factoryName,
            value: item.factoryId
          });
          this.factoryList.push({
            factoryId: item.factoryId,
            factoryName: item.factoryName
          });
        });
        return options;
      }
    },
    // 获取数据字典 配件部位功用
    onLoadDict() {
      fetchDict("product_accessory").then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.positionList = resp.data.data;
          if (this.positionList) {
            this.positionList.forEach(item => {
              item.label = item.itemName;
              item.value = item.itemName;
            });
          }
        }
      });
    },
    positionOnChange(val) {
      this.positionList.forEach(item => {
        if (item.itemName === val) {
          item.subItems.forEach(i => {
            i.label = i.itemName;
            i.value = i.itemName;
          });
          this.funcList = item.subItems;
          this.form.functionality = "";
        } else {
          this.form.functionality = "";
        }
      });
    },
    onDetail(record) {
      this.$router.push({
        name: "PartsDetail",
        params: { id: record.productId, type: "detail" }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onLoadList() {
      const params = {
        pageNum: 0,
        pageSize: 15,
        productCode: "",
        productName: "",
        productStatus: -1,
        productType: "配件"
      };
      this.fetchList(params);
    },
    fetchList(params) {
      this.loading = true;
      fetchProductList({
        ...params,
        functionality: this.form.functionality,
        position: this.form.position,
        factoryId: this.isOperationHeadquarters
          ? params.factoryId
          : localStorage.getItem("corporationId")
      })
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            this.tblData.forEach(item => {
              if (item.enableFlag) item.productStatus = "上架";
              else item.productStatus = "下架";
              item.createTime = tsFormat(item.createTime);
              item.modifiedTime = moment(item.modifiedTime).format(
                "YYYY-MM-DD HH:MM:SS"
              );
            });
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onEnableFlagUpdate(record) {
      const param = {
        enableFlag: !record.enableFlag,
        productIds: [record.productId]
      };
      modifyProductStatus(param)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("操作成功");
            this.selectedRowKeys = [];
            this.onSearch();
          }
        })
        .catch(error => {
          if (error) {
            console.log(error);
          }
        });
    },
    onDelete(record) {
      const hide = this.$message.loading("产品删除中", 0);
      deleteProduct(record.productId)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("删除成功");
            this.selectedRowKeys = [];
            this.onSearch();
          } else {
            this.$message.success("删除失败");
          }
          setTimeout(hide, 0);
        })
        .catch(error => {
          if (error) {
            console.log(error);
          }
          this.$message.success("删除失败");
          setTimeout(hide, 0);
        });
    },
    onBatchChange(status) {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请至少选择一个产品");
        return;
      }
      const param = {
        enableFlag: status,
        productIds: this.selectedRowKeys
      };
      modifyProductStatus(param)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("操作成功");
            this.selectedRowKeys = [];
            this.onSearch();
          }
        })
        .catch(error => {
          if (error) {
            console.log(error);
          }
        });
    },
    onSearch(form) {
      this.info = form;
      this.pagination.current = 1;
      const params = {
        ...form,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 15,
        productType: "配件"
      };
      this.fetchList(params);
    },
    onPage(p) {
      this.pagination.current = p.current;
      const params = {
        ...this.info,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 15,
        productType: "配件"
      };
      this.fetchList(params);
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSet() {
      this.setStandardVisible = true;
    },
    onSetStandard() {
      this.setStandardVisible = false;
    },
    onNew() {
      this.$router.push({
        name: "PartsAdd",
        params: { type: "add" }
      });
    },
    onReset() {
      this.form.functionality = "";
      this.form.position = "";
    },
    // 刷新
    onRefresh() {
      this.form.functionality = "";
      this.form.position = "";
      this.$refs.search.onReset();
    }
  }
};
</script>

<style scoped>
.button-container-second {
  margin-bottom: 20px;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.set-standard-container {
  display: flex;
  align-items: flex-end;
}
.input-standard {
  width: 100px;
  margin-right: 10px;
}
.standard-word {
  height: 32px;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.set-standard-container-two {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
.unit {
  width: 20px;
}
.common-search_form-item {
  width: 25%;
  display: inline-flex;
  margin-bottom: 24px;
}
.product-img-container {
  width: 100px;
  height: 100px;
}
.product-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.row {
  display: flex;
}
.col {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
</style>
