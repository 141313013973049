<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <span>
            产品管理
          </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="cursor: pointer">
          <span @click="onBack">
            配件产品列表
          </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="type === 'add'">新增产品</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-form :form="infoForm" class="advanced-search-form">
      <div class="info-container">
        <a-row>
          <a-col><div class="info-title">产品信息</div></a-col>
        </a-row>

        <a-row>
          <a-col :span="8">
            <a-form-item label="产品编码">
              <a-input
                v-if="type === 'add'"
                v-decorator="[
                  'productCode',
                  { rules: [{ required: true, message: '请输入产品编码' }] }
                ]"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品名称">
              <a-input
                v-if="type === 'add'"
                v-decorator="[
                  'productName',
                  { rules: [{ required: true, message: '请输入产品名称' }] }
                ]"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品类型">
              配件
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="产品部位">
              <a-select
                v-if="type === 'add'"
                v-decorator="[
                  'position',
                  {
                    initialValue: '',
                    rules: [{ required: true, message: '请选择产品部位' }]
                  }
                ]"
                allow-clear
                :options="positionList"
                @change="positionOnChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8" class="select-container">
            <a-form-item label="产品功用">
              <a-select
                v-if="type === 'add'"
                v-decorator="[
                  'functionality',
                  {
                    initialValue: '',
                    rules: [{ required: true, message: '请选择产品功用' }]
                  }
                ]"
                allow-clear
                :options="funcList"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="制造原厂" class="form-item">
              <a-select
                v-decorator="[
                  'factoryId',
                  { rules: [{ required: true, message: '请选择制造原厂' }] }
                ]"
                class="input"
                show-search
                allow-clear
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in factoryList"
                  :key="item.factoryId"
                >
                  {{ item.factoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="pro-status">
        <a-row>
          <a-col><div class="info-title">产品状态</div></a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <a-radio-group
                v-decorator="['onShelfStatus', { initialValue: '0' }]"
              >
                <a-radio value="1" disabled>
                  上架
                </a-radio>
                <a-radio value="0">
                  下架
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="commodity-specifications">
        <a-row>
          <a-col>
            <div class="info-title">商品规格</div>
            <a-tag
              v-if="type !== 'detail'"
              class="add-speci-tag"
              @click="addSpeci"
            >
              添加规格
            </a-tag>
            <div
              v-for="(item, index) in speciList"
              :key="index"
              class="commodity-content"
            >
              <div class="tag-name-container">
                {{ item.tagName }}
                <img
                  v-if="type !== 'detail'"
                  src="../../assets/imgs/delete_icon.png"
                  alt="删除"
                  @click="onDeleteSpeci(index)"
                />
                <div
                  class="tag-name-button"
                  v-if="index !== 0"
                  @click="moveUpSpeci(index)"
                >
                  上移
                </div>
                <div
                  class="tag-name-button"
                  v-if="index !== speciList.length - 1"
                  @click="moveDownSpeci(index)"
                >
                  下移
                </div>
              </div>
              <add-tag
                :list="item.list"
                :tag-name="item.tagName"
                :show="type !== 'detail'"
                @addTag="onAddTags"
                @removeTag="onRemoveTags"
              />
            </div>
            <div v-if="speciVisible" class="add-speci-container">
              <a-form
                :form="speciForm"
                class="advanced-search-form"
                @submit="onAddSpeci"
              >
                <a-row>
                  <a-col :span="6">
                    <a-form-item label="规格名">
                      <a-input
                        v-decorator="[
                          'tagName',
                          {
                            rules: [{ required: true, message: '请输入规格名' }]
                          }
                        ]"
                        placeholder="请填入规格名"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="6">
                    <a-form-item label="规格值">
                      <a-input
                        v-decorator="[
                          'value',
                          {
                            rules: [{ required: true, message: '请输入规格值' }]
                          }
                        ]"
                        placeholder="请填入规格值"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="2">
                    <a-button type="primary" html-type="submit">确定</a-button>
                  </a-col>
                  <a-col :span="2">
                    <a-button @click="speciVisible = false">取消</a-button>
                  </a-col>
                </a-row>
              </a-form>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="commodity-info">
        <div class="commodity-title">
          <div class="info-title">商品信息</div>
          <a-button type="primary" @click="onJudgeAddSku">
            新增商品
          </a-button>
          <a-modal
            title=""
            :visible="visible"
            :confirm-loading="confirmLoading"
            @ok="handleOk"
            @cancel="handleCancel"
          >
            {{ ModalText }}
          </a-modal>
        </div>
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tableColumns"
          :pagination="false"
          :loading="loading"
          row-key="skuCode"
          :scroll="{ x: 1300 }"
        >
          <template slot="vehicleName" slot-scope="text">
            <div class="vehicle__name">{{ text }}</div>
          </template>
          <template slot="hasSn" slot-scope="text">
            {{ text ? "是" : "否" }}
          </template>
          <template slot="hasBatchNo" slot-scope="text">
            {{ text ? "是" : "否" }}
          </template>
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img-container"
          >
            <img
              v-if="record.skuImages && record.skuImages.length > 0"
              :src="imageBaseUrl + record.skuImages[0].fileUrl"
              :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
              alt="产品图片"
            />
            <div v-else></div>
          </div>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDeleteTable(record)">删除</a>
            <a @click="onModifyVisible(record)">修改</a>
          </a-space>
        </a-table>
      </div>
      <div class="commodity-introduce">
        <div class="info-title">产品介绍</div>
        <ckeditor v-model="proIntroduce" :config="editorConfig"></ckeditor>
      </div>

      <div class="examine-container">
        <a-button v-if="type !== 'detail'" class="button-item" @click="onAdd">
          完成
        </a-button>
        <a-button v-else class="button-item" @click="onModify">
          修改
        </a-button>
      </div>
    </a-form>
    <!--修改商品信息弹窗-->
    <a-modal v-model="modifyVisible" title="修改商品信息" @ok="onModifyComm">
      <a-form :form="modifyForm" class="advanced-search-form">
        <a-form-item label="商品编码">
          {{ skuCode }}
        </a-form-item>
        <a-form-item label="商品图" extra="图片大小限制在20MB以内">
          <a-upload
            v-decorator="[
              'skuImgs',
              {
                rules: [{ required: true, message: '请上传商品图' }],
                initialValue: skuImgs
              }
            ]"
            name="file"
            list-type="picture-card"
            accept=".png,.jpg,.jpeg,.gif,.svg"
            :show-upload-list="true"
            :action="imgUrl"
            :file-list="skuImgs"
            :before-upload="beforeUploadFile"
            @preview="handlePreviewSkuImage"
            @change="handleChangeSkuImg"
          >
            <div v-if="skuImgs.length < 8">
              <a-icon type="plus" />
            </div>
          </a-upload>
          <a-modal
            style="z-index: 99"
            :visible="previewVisible"
            @ok="previewVisible = false"
            @cancel="previewVisible = false"
          >
            <img
              :src="previewImage"
              alt="logo"
              style="width: 100%"
              :footer="null"
            />
          </a-modal>
        </a-form-item>
        <a-form-item label="适配车型">
          <a-tree-select
            v-decorator="['id']"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="productTree"
            multiple
            :replace-fields="productTreeFields"
            tree-node-filter-prop="title"
            :allow-clear="true"
            tree-checkable
            :show-checked-strategy="SHOW_PARENT"
          ></a-tree-select>
        </a-form-item>
        <a-form-item label="出厂价">
          <a-input
            v-decorator="[
              'wholeSellPrice',
              { rules: [{ required: true, message: '请输入出厂价' }] }
            ]"
            placeholder="请填入出厂价"
          />
        </a-form-item>
        <a-form-item label="零售指导价">
          <a-input
            v-decorator="[
              'retailPrice',
              { rules: [{ required: true, message: '请输入零售指导价' }] }
            ]"
            placeholder="请填入零售指导价"
          />
        </a-form-item>
        <a-form-item label="基础价">
          <a-input
            v-decorator="[
              'platformPrice',
              { rules: [{ required: true, message: '请输入基础价' }] }
            ]"
            placeholder="请填入基础价"
          />
        </a-form-item>
        <a-form-item label="是否具有序列号">
          <a-select
            v-decorator="[
              'hasSn',
              {
                rules: [{ required: true, message: '请选择是否具有序列号' }],
                initialValue: true
              }
            ]"
            :options="[
              { label: '是', value: true },
              { label: '否', value: false }
            ]"
          ></a-select>
        </a-form-item>
        <a-form-item label="是否具有批次号">
          <a-select
            v-decorator="[
              'hasBatchNo',
              {
                rules: [{ required: true, message: '请选择是否具有批次号' }],
                initialValue: true
              }
            ]"
            :options="[
              { label: '是', value: true },
              { label: '否', value: false }
            ]"
          ></a-select>
        </a-form-item>
        <a-form-item label="商品体积">
          <a-input
            v-decorator="[
              'skuVolume',
              { rules: [{ required: true, message: '请输入商品体积' }] }
            ]"
            placeholder="请填入商品体积"
            suffix="m³"
          />
        </a-form-item>
        <a-form-item label="装运体积">
          <a-input
            v-decorator="[
              'packVolume',
              { rules: [{ required: true, message: '请输入装运体积' }] }
            ]"
            placeholder="请填入装运体积"
            suffix="m³"
          />
        </a-form-item>
        <a-form-item label="商品重量">
          <a-input
            v-decorator="[
              'skuWeight',
              { rules: [{ required: true, message: '请输入商品重量' }] }
            ]"
            placeholder="请填入商品重量"
            suffix="kg"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 新增商品弹窗   -->
    <a-modal v-model="addVisible" title="新增商品" @ok="onSubmitCommodity">
      <a-form
        :form="addForm"
        class="advanced-search-form"
        @submit="onSubmitCommodity"
      >
        <a-form-item label="商品编码">
          <a-input
            v-decorator="[
              'skuCode',
              { rules: [{ required: true, message: '请输入商品编码' }] }
            ]"
            placeholder="请填入商品编码"
            @blur="onJudgeCommon"
          />
        </a-form-item>
        <a-form-item
          v-for="(item, index) in speciList"
          :key="index"
          :label="item.tagName"
        >
          <a-select
            v-decorator="[
              `keyPropsMap.${item.tagName}`,
              { rules: [{ required: true, message: `请输入${item.tagName}` }] }
            ]"
            :options="item.list"
            :placeholder="`请输入${item.tagName}`"
          />
        </a-form-item>
        <a-form-item label="商品图" extra="图片大小限制在20MB以内">
          <a-upload
            v-decorator="[
              'skuImgs',
              {
                rules: [{ required: true, message: '请上传商品图' }],
                initialValue: skuImgs
              }
            ]"
            name="file"
            list-type="picture-card"
            accept=".png,.jpg,.jpeg,.gif,.svg"
            :show-upload-list="true"
            :action="imgUrl"
            :file-list="skuImages"
            :before-upload="beforeUploadFile"
            @preview="handlePreviewSkuImage"
            @change="handleChangeSkuImage"
          >
            <div v-if="skuImages.length < 8">
              <a-icon type="plus" />
            </div>
          </a-upload>
          <a-modal
            style="z-index: 99"
            :visible="previewVisible"
            @ok="previewVisible = false"
            @cancel="previewVisible = false"
          >
            <img
              :src="previewImage"
              alt="logo"
              style="width: 100%"
              :footer="null"
            />
          </a-modal>
        </a-form-item>
        <a-form-item label="适配车型">
          <a-tree-select
            v-decorator="['id']"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="productTree"
            multiple
            :replace-fields="productTreeFields"
            tree-node-filter-prop="title"
            :allow-clear="true"
            tree-checkable
            :show-checked-strategy="SHOW_PARENT"
          ></a-tree-select>
        </a-form-item>
        <a-form-item label="出厂价">
          <a-input
            v-decorator="[
              'wholeSellPrice',
              { rules: [{ required: true, message: '请输入出厂价' }] }
            ]"
            placeholder="请填入出厂价"
          />
        </a-form-item>
        <a-form-item label="零售指导价">
          <a-input
            v-decorator="[
              'retailPrice',
              { rules: [{ required: true, message: '请输入零售指导价' }] }
            ]"
            placeholder="请填入零售指导价"
          />
        </a-form-item>
        <a-form-item label="基础价">
          <a-input
            v-decorator="[
              'platformPrice',
              { rules: [{ required: true, message: '请输入基础价' }] }
            ]"
            placeholder="请填入基础价"
          />
        </a-form-item>
        <a-form-item label="是否具有序列号">
          <a-select
            v-decorator="[
              'hasSn',
              {
                rules: [{ required: true, message: '请选择是否具有序列号' }],
                initialValue: true
              }
            ]"
            :options="[
              { label: '是', value: true },
              { label: '否', value: false }
            ]"
          ></a-select>
        </a-form-item>
        <a-form-item label="是否具有批次号">
          <a-select
            v-decorator="[
              'hasBatchNo',
              {
                rules: [{ required: true, message: '请选择是否具有批次号' }],
                initialValue: true
              }
            ]"
            :options="[
              { label: '是', value: true },
              { label: '否', value: false }
            ]"
          ></a-select>
        </a-form-item>
        <a-form-item label="商品体积">
          <a-input
            v-decorator="[
              'skuVolume',
              { rules: [{ required: true, message: '请输入商品体积' }] }
            ]"
            placeholder="请填入商品体积"
            suffix="m³"
          />
        </a-form-item>
        <a-form-item label="装运体积">
          <a-input
            v-decorator="[
              'packVolume',
              { rules: [{ required: true, message: '请输入装运体积' }] }
            ]"
            placeholder="请填入装运体积"
            suffix="m³"
          />
        </a-form-item>
        <a-form-item label="商品重量">
          <a-input
            v-decorator="[
              'skuWeight',
              { rules: [{ required: true, message: '请输入商品重量' }] }
            ]"
            placeholder="请填入商品重量"
            suffix="kg"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script type="text/ecmascript-6">
import {
  addProduct,
  checkCommonSku,
  checkProductCodeExists,
  fetchAllFactoriesList,
  fetchProductIdBySkuId,
  fetchProductTreeCar
} from "@/services/ProductService";
// import TinyMce from "../../components/TinyMce";
// import tinymce from "tinymce/tinymce";
import { imgUrl, upload } from "@/services/UploadService";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import AddTag from "../../components/AddTag";
import { fetchDict } from "@/services/DictService";
import { TreeSelect } from "ant-design-vue";

const tblColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: 150
  },
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" },
    width: 200
  },
  {
    title: "适配车型",
    dataIndex: "vehicleName",
    scopedSlots: { customRender: "vehicleName" },
    width: 250
  },
  {
    title: "出厂价",
    dataIndex: "wholeSellPrice",
    width: 150
  },
  {
    title: "零售指导价",
    dataIndex: "retailPrice",
    width: 150
  },
  {
    title: "基础价",
    dataIndex: "platformPrice",
    width: 150
  },
  {
    title: "是否具有序列号",
    dataIndex: "hasSn",
    scopedSlots: {customRender: "hasSn"},
    width: 110
  },
  {
    title: "是否具有批次号",
    dataIndex: "hasBatchNo",
    scopedSlots: {customRender: "hasBatchNo"},
    width: 110
  },
  {
    title: "商品体积（m³）",
    dataIndex: "skuVolume",
    width: 150
  },
  {
    title: "装运体积（m³）",
    dataIndex: "packVolume",
    width: 150
  },
  {
    title: "商品重量（kg）",
    dataIndex: "skuWeight",
    width: 150
  },
  {
    title: "操作",
    key: "action",
    fixed: "right",
    scopedSlots: { customRender: "action" },
    width: 300
  }
];
const productTreeFields = {
  children: "skuList",
  title: "name",
  key: "id",
  value: "id"
};
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  components: {
    AddTag
  },
  data() {
    return {
      type: this.$route.params.type,
      productId: this.$route.params.id,
      imgUrl,
      imageBaseUrl,
      visible: false,
      confirmLoading: false,
      ModalText: "",
      infoForm: this.$form.createForm(this),
      onShelfStatus: "1",
      tblData: [],
      factoryList: [],
      tblColumns,
      tableColumns:[],
      loading: false,
      proIntroduce: "",
      editorConfig: {
        removePlugins: ["about"],
        removeDialogTabs: "image:advanced;image:Link", // 隐藏超链接与高级选项
        image_previewText: " ", // 预览区域显示内容
        // 该插件使用<div>元素（而不是传统的<iframe>元素）作为主题编辑器中的可编辑区域
        extraPlugins: "divarea",
        filebrowserUploadUrl: `${baseURL}/upload-ckeditor/product_intro_pic`, // 上传图片路径
        // 是否强制复制来的内容去除格式 plugins/pastetext/plugin.js
        forcePasteAsPlainText: true, // 去除
        // 去除内容过滤
        allowedContent: true,
        // 折叠工具栏
        toolbarCanCollapse: true,
        // https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html#cfg-enterMode
        enterMode: 2,
        // 只读模式
        readOnly: false,
        toolbarGroups: [
          { name: "clipboard", groups: ["clipboard", "undo"] },
          { name: "insert" },
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"]
          },
          { name: "links" },
          { name: "styles" }
        ]
      },
      addVisible: false,
      addForm: this.$form.createForm(this),
      previewVisible: false,
      previewImage: "",
      skuImages: [],
      newSpeciList: [],
      positionList: [],
      funcList: [],
      content: "<p>html content</p>",
      setting: {
        height: 500,
        language_url: "/public/tinymce/langs/zh_CN.js", // 汉化路径是自定义的，一般放在public或static里面
        language: "zh_CN"
      },
      infoDetail:{},
      speciList:[

      ],
      speciVisible: false,
      speci: {
        tagName: "",
        list: []
      },
      speciForm: this.$form.createForm(this),
      modifyVisible: false,
      skuCode: "",
      modifyForm: this.$form.createForm(this),
      skuImgs: [],
      imgList: [],
      productTree: [],
      productTreeFields,
      SHOW_PARENT
    };
  },
  beforeRouteEnter(to, from, next) {
    localStorage.removeItem("tblData");
    next();
  },
  mounted() {
    this.loadCarProductTree();
    this.onLoadFactory();
    this.newSpeciList = [];
    if (
      localStorage.getItem("tblData") &&
      JSON.parse(localStorage.getItem("tblData")).length > 0
    ) {
      JSON.parse(localStorage.getItem("tblData")).forEach(item => {
        this.newSpeciList.push(item.keyPropsMap);
      });
    }
    this.tableColumns=[...this.tblColumns]
    // tinymce.init({})
    // this.dutyDetailClick();
    this.onLoadDict();
  },
  methods: {
    onJudgeAddSku() {
      this.addVisible = true;
      this.addForm.resetFields();
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      this.visible = false;
    },
    // 获取全部制造原厂
    onLoadFactory() {
      fetchAllFactoriesList().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.factoryList = resp.data.data.records;
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取数据字典 配件部位功用
    onLoadDict() {
      fetchDict("product_accessory").then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.positionList = resp.data.data;
          if (this.positionList) {
            this.positionList.forEach(item => {
              item.label = item.itemName;
              item.value = item.itemName;
            });
          }
        }
      })
    },
    onDeleteTable(record){
      localStorage.removeItem("tblData");
      this.tblData.forEach((item,index)=>{
        if(item.skuCode===record.skuCode)
          this.tblData.splice(index,1)
      })
      localStorage.setItem("tblData", JSON.stringify(this.tblData));
      this.newSpeciList = [];
      JSON.parse(localStorage.getItem("tblData")).forEach(item => {
        this.newSpeciList.push(item.keyPropsMap);
      });
    },
    onModifyVisible(record){
      this.skuCode=record.skuCode;
      this.modifyVisible=true;
      setTimeout(()=>{
        this.modifyForm.setFieldsValue({
          skuCode: record.skuCode,
          wholeSellPrice: record.wholeSellPrice,
          retailPrice: record.retailPrice,
          platformPrice: record.platformPrice,
          id: record.id,
          skuVolume: record.skuVolume,
          packVolume: record.packVolume,
          skuWeight: record.skuWeight,
        });
      },100)
      this.skuImgs=[]
      this.imgList=record.skuImages
      if (record.skuImages && record.skuImages.length > 0) {
        record.skuImages.forEach((item,index)=>{
          this.skuImgs.push({
            uid: index,
            name: item.fileName,
            url: imageBaseUrl + item.fileUrl
          });
        })
      }

    },
    onDeleteSpeci(index){
      if (
        !localStorage.getItem("tblData") ||
        !JSON.parse(localStorage.getItem("tblData")).length > 0
      ) {
        this.speciList.splice(index, 1);
        this.tableColumns.splice(index + 2, 1);
      }else {
        this.$message.error("请删除已有商品后进行操作");
      }
    },
    onAddSpeci(e){
      e.preventDefault();
      e.stopPropagation();
      this.speciForm.validateFields((err,values)=>{
        if(!err){
          const list=[];
          list.push({value:values.value,label:values.value})
          this.speciList.push({
            tagName:values.tagName,
            list:list
          });
          const name=`keyPropsMap.${values.tagName}`;
          const item={
            title:values.tagName,
            dataIndex:name,
            width: 150
          }
          this.tableColumns.splice(1+this.speciList.length,0,item)
          this.speciForm.setFieldsValue({
            tagName:"",
            value:""
          });
          this.speciVisible = false;
        }
      })
    },
    addSpeci(){
      if (!localStorage.getItem("tblData") ||
        !JSON.parse(localStorage.getItem("tblData")).length > 0) {
        this.speciVisible = true;
      } else {
        this.$message.error("请删除已有商品后进行操作");
      }
    },
    dutyDetailClick() {
      this.$nextTick(() => {
        let html =
          "<p><span style='color: rgb(224, 62, 45);' data-mce-style='color: #e03e2d;'>1、方便；</span></p><p>2、快捷；</p>";
        if (!html) {
          html = "";
        }
        // 给富文本框赋值
        this.$refs.tinymceRef.$el
          .querySelector("iframe")
          .contentDocument.querySelector("body").innerHTML = html;
      });
    },
    onBack() {
      this.$router.push({ name: "Parts" });
    },
    onPage() {},
    onAdd() {
      this.$store.state.isModify = false;
      this.infoForm.validateFields((err, values) => {
        if (localStorage.getItem("tblData") && JSON.parse(localStorage.getItem("tblData")).length > 0) {
          if (!err) {
            values.onShelfStatus = parseInt(values.onShelfStatus);
            const param = {
              ...values,
              skuFormList: this.tblData,
              productDescription: this.proIntroduce,
              productType: "配件",
              specifications: this.getSpeciStr()
            };
            if (this.type === "add") {
              addProduct(param).then(resp => {
                if (resp.data.code === "SUCCESS") {
                  this.$message.success("新增成功");
                  this.$router.push({ name: "Parts" });
                }
              });
            }
          }
        }else{
          this.visible = true;
          this.ModalText = "请添加商品";
        }
      });
    },
    async handlePreviewExhibition(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeExhibition({ fileList }) {
      this.productImgs = fileList;
    },
    async handlePreviewSkuImage(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeSkuImage({ fileList }) {
      this.skuImages = fileList.filter(item => item.url);
    },
    handleChangeSkuImg({ fileList }) {
      this.skuImgs = fileList.filter(item => item.url);
    },
    beforeUploadFile(file) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("图片名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("图片大小超过20MB");
        } else {
          this.uploadImage("product_picture", file);
        }
      }
      return false;
    },
    async uploadImage(type, file) {
      const { data = {} } = await upload(type, file, "other");
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        if (this.addVisible) {
          // 新增
          this.skuImages.push({
            uid: this.skuImages.length,
            name: file.name,
            url: imageBaseUrl + data.data,
            response: data
          });
        }
        if (this.modifyVisible) {
          // 编辑
          this.skuImgs.push({
            uid: this.skuImgs.length,
            name: file.name,
            url: imageBaseUrl + data.data,
          });
        }
      }
    },
    onSubmitCommodity() {
      this.addForm.validateFields(async(err, values) => {
        if (!err) {
          let list = [];
          let productNameList = [];
          if (values.id && values.id.length > 0) {
            for (const ele of values.id) {
              let index = this.productTree.findIndex(e => ele === e.productId);
              if (index >= 0) {
                let skuList = this.productTree[index].skuList;
                if (skuList && skuList.length > 0) {
                  skuList.forEach(sub => {
                    let params = {
                      productId: this.productTree[index].productId,
                      skuId: sub.skuId
                    };
                    list.push(params);
                    if (sub.keyPropsMap) {
                      let str = this.productTree[index].productName;
                      for (const paramsKey in sub.keyPropsMap) {
                        str = str + sub.keyPropsMap[paramsKey];
                      }
                      productNameList.push(str);
                    }
                  });
                }
              } else {
                const resp = await fetchProductIdBySkuId(ele);
                let params = {
                  productId: resp.data.data,
                  skuId: ele
                };
                list.push(params);
                let index = this.productTree.findIndex(e => e.productId === resp.data.data);
                if (index >= 0) {
                  let subItem = this.productTree[index].skuList.find(sub => sub.skuId === ele);
                  let str = this.productTree[index].productName;
                  for (const subItemKey in subItem.keyPropsMap) {
                    str = str + subItem.keyPropsMap[subItemKey];
                  }
                  productNameList.push(str);
                }
              }
            }
          }
          if (this.skuImages.length === 0) {
            this.$message.info("请上传商品图");
          } else {
            const imgList = [];
            if (this.skuImages.length > 0) {
              this.skuImages.forEach(item => {
                const param = {
                  fileName: item.name,
                  fileUrl: item.response.data
                };
                imgList.push(param);
              });
            }
            const skuForm = {
              ...values,
              skuImages: imgList,
              relationList: list,
              vehicleName: productNameList.length > 0 ? productNameList.join(",") : ""
            };
            this.tblData.push(skuForm);
            this.skuImages = [];
            this.addForm.resetFields();
            localStorage.setItem("tblData", JSON.stringify(this.tblData));
            this.addVisible = false;
          }
          this.newSpeciList = [];
          JSON.parse(localStorage.getItem("tblData")).forEach(item => {
            this.newSpeciList.push(item.keyPropsMap);
          });
        }
      });
    },
    onModifyComm(){
      this.modifyForm.validateFields(async(err, values) => {
        if (!err) {
          let list = [];
          let productNameList = [];
          if (values.id && values.id.length > 0) {
            for (const ele of values.id) {
              let index = this.productTree.findIndex(e => ele === e.productId);
              if (index >= 0) {
                let skuList = this.productTree[index].skuList;
                if (skuList && skuList.length > 0) {
                  skuList.forEach(sub => {
                    let params = {
                      productId: this.productTree[index].productId,
                      skuId: sub.skuId
                    };
                    list.push(params);
                    if (sub.keyPropsMap) {
                      let str = this.productTree[index].productName;
                      for (const paramsKey in sub.keyPropsMap) {
                        str = str + sub.keyPropsMap[paramsKey];
                      }
                      productNameList.push(str);
                    }
                  });
                }
              } else {
                const resp = await fetchProductIdBySkuId(ele);
                let params = {
                  productId: resp.data.data,
                  skuId: ele
                };
                list.push(params);
                let index = this.productTree.findIndex(e => e.productId === resp.data.data);
                if (index >= 0) {
                  let subItem = this.productTree[index].skuList.find(sub => sub.skuId === ele);
                  let str = this.productTree[index].productName;
                  for (const subItemKey in subItem.keyPropsMap) {
                    str = str + subItem.keyPropsMap[subItemKey];
                  }
                  productNameList.push(str);
                }
              }
            }
          }
          if (this.skuImgs.length === 0) {
            this.$message.info("请上传商品图");
          } else {

            if (this.skuImgs.length > 0) {
              this.skuImgs.forEach(item => {
                if (item.response) {
                  this.imgList.push({
                    fileName: item.name,
                    fileUrl: item.response.data
                  });
                  item.response = "";
                }
              });
            }
            this.tblData.forEach(item=>{
              if(item.skuCode===this.skuCode) {
                item.skuImages = this.imgList;
                item.wholeSellPrice = values.wholeSellPrice;
                item.retailPrice = values.retailPrice;
                item.vehicleName = productNameList.length > 0 ? productNameList.join(",") : "";
                item.relationList = list;
                item.skuVolume = values.skuVolume;
                item.packVolume = values.packVolume;
                item.skuWeight = values.skuWeight;
              }
            });
            this.skuImgs=[];
            this.modifyForm.resetFields();
            localStorage.setItem("tblData", JSON.stringify(this.tblData));
            this.modifyVisible = false;
          }
        }
      });
    },
    onModify(){
      this.type="modify";
      setTimeout(()=>{
        this.infoForm.setFieldsValue({
          productCode: this.infoDetail.productCode,
          productName: this.infoDetail.productName,
          productDescription: this.infoDetail.productDescription,
          packVolume: this.infoDetail.packVolume,
          productVolume: this.infoDetail.productVolume,
          productWeight: this.infoDetail.productWeight,
          functionality: this.infoDetail.functionality,
          factoryId: this.infoDetail.factoryId,
          position: this.infoDetail.position,
          carIds: this.infoDetail.carIds
        });
      },500)
    },
    onAddTags(params){
      this.speciList.forEach(item=>{
        if(item.tagName===params.tagName){
          item.list.push(params.value)
        }
      })
      if (this.speciList.length > 0) {
        localStorage.setItem("specifications", this.getSpeciStr());
      }
    },
    onRemoveTags(params) {
      let isExist = false;
      if (this.newSpeciList.length > 0) {
        this.newSpeciList.forEach(items => {
          for (const key in items) {
            if (key === params.tagName) {
              if (items[key] === params.value.value) {
                isExist = true;
              }
            }
          }
        });
      }
      if (!isExist || this.tblData.length === 0) {
        this.speciList.forEach(item => {
          if (item.tagName === params.tagName) {
            item.list.forEach((tag, tagInd) => {
              if (tag.value === params.value.value) {
                item.list.splice(tagInd, 1);
              }
            });
          }
        });
        if (this.speciList.length > 0) {
          localStorage.setItem("specifications", this.getSpeciStr());
        }
      } else {
        this.$message.error("请删除已有商品后进行操作");
      }
    },
    positionOnChange(val) {
      this.positionList.forEach(item => {
        if (item.itemName === val) {
          item.subItems.forEach(i => {
            i.label = i.itemName;
            i.value = i.itemName;
          });
          this.funcList = item.subItems;
          this.infoForm.setFieldsValue({
            functionality: ""
          });
        }else{
          this.infoForm.setFieldsValue({
            functionality: ""
          });
        }
      });
    },
    formatList(dataList) {
      let str = "";
      let cnt = 0;
      dataList.forEach(data => {
        if (cnt < dataList.length - 1) {
          str = `${str + data  }、`;
          cnt = cnt +1;
        } else {
          str = str + data;
        }
      });
      return str;
    },
    onJudgeCommon() {
      const skuCode = this.addForm.getFieldsValue().skuCode;
      const factoryId = localStorage.getItem("factoryId");
      checkCommonSku(factoryId, skuCode).then(resp => {
        if (resp.data.code === "SUCCESS") {
          if (resp.data.data === true) {
            this.$message.info("输入的商品编码已存在，请重新输入");
            this.addForm.setFieldsValue({ skuCode: "" });
          }
        }
      });
    },
    // 校验产品编码是否存在
    checkProductCodeExists(rule, value, callback) {
      if (value) {
        checkProductCodeExists(value).then(resp => {
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data) {
              callback("产品编码已存在");
            } else {
              callback();
            }
          }
        });
      }
    },
    // 上移规格
    moveUpSpeci(index) {
      const indexItem = this.speciList[index];
      const prevItem = this.speciList[index - 1];
      this.speciList = this.speciList.map((item, i) => {
        if (i === index - 1) return indexItem;
        if (i === index) return prevItem;
        return item;
      });
    },
    // 下移规格
    moveDownSpeci(index) {
      const indexItem = this.speciList[index];
      const nextItem = this.speciList[index + 1];
      this.speciList = this.speciList.map((item, i) => {
        if (i === index) return nextItem;
        if (i === index + 1) return indexItem;
        return item;
      });
    },
    // 把规格转成字符串
    getSpeciStr() {
      return JSON.stringify(
        this.speciList.map(item => ({
          [item.tagName]: item.list.map(v => v.value)
        })) || []
      );
    },
    // 获取整车产品树
    loadCarProductTree() {
      fetchProductTreeCar().then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          const productList = [];
          data.forEach(item => {
            if (item.skuList && item.skuList.length > 0) {
              productList.push(item);
            }
          });
          this.productTree = productList;
          if (this.productTree.length > 0) {
            this.productTree.forEach(item => {
              item.id = item.productId;
              item.name = `${item.productName} ${item.productCode}`;
              if (item.skuList && item.skuList.length > 0) {
                item.skuList.forEach(items => {
                  items.id = items.skuId;
                  let skuName = "";
                  if (items.keyPropsMap) {
                    for (const [key, value] of Object.entries(
                      items.keyPropsMap
                    )) {
                      if (key) {
                        skuName += `${value} `;
                      }
                    }
                  }
                  items.name = `${items.skuCode} ${skuName}`;
                });
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.breadcrumb-title {
  cursor: pointer;
}
.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 5px 30px;
}
.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.pro-status {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  padding-top: 40px;
}
.commodity-specifications {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.commodity-info {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.commodity-introduce {
  background-color: white;
  margin: 10px 20px 60px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.commodity-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commodity-table {
  margin-top: 20px;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
/*/deep/ .ant-upload-picture-card-wrapper {*/
/*  display: flex;*/
/*}*/
.img-container {
  width: 100px;
  height: 100px;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}
.add-speci-container /deep/ .ant-form-item .ant-form-item-label {
  min-width: 60px;
}
.add-speci-container .ant-col {
  height: 60px;
}
.tag-name-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.tag-name-container img {
  cursor: pointer;
  width: 20px;
  object-fit: contain;
}
.tag-name-container .tag-name-button {
  color: #4368f6;
  margin-left: 10px;
  cursor: pointer;
}
.commodity-content {
  margin-bottom: 10px;
}
/deep/.ant-tag {
  line-height: 30px;
}

.add-speci-tag {
  background-color: #4368f6;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
}

.vehicle__name {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
</style>
